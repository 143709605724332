import moment from "moment";
import ApiService from "../services/ApiService";
interface ProductionStock {
  id: number;
  qty: string;
  remark: string;
  stock_type: string;
  production_id: string;
  production_data: any;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const ProductionStocks: Array<ProductionStock> = [];

const deleteProductionStock = function (item: ProductionStock) {
  return ApiService.delete(`production-stock/${item.id}/`);
};

const addProductionStock = function (item, id) {
  delete item.created_by
  if (id) {
    return ApiService.put(`production-stock/${id}/`, item);
  } else {
    return ApiService.post("production-stock/", item);
  }
};

const exportProductionStockData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile(
      "export/production-stock/?productionStock_id=" + ids,
      "productionStock-data"
    ).then((data) => res(data));
  });
};
const exportPDFProductionStocks = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/production-stock/?productionStock_id=" + ids).then((data) =>
      res(data)
    );
  });
};
const productionStockObjClone = function () {
  return {
    id: 0,
    qty: "",
    remark: "",
    stock_type: "",
    production_id: "",
    production_data: {},
    created_by: "",
  };
};
const jsonToInterface = function (item: any) {
  const newItem: ProductionStock = productionStockObjClone();
  newItem.id = item.id;
  newItem.qty = item.qty;
  newItem.remark = item.remark;
  newItem.stock_type = item.stock_type;
  newItem.production_id = item.production_id;
  newItem.production_data = item.production_data;
  newItem.created_by = item.created_by;
  return newItem;
};
const getProductionStockById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`production-stock/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};

const getProductionStocks = function (
  pageNumber = 1,
  searchData,
  status = "",
  production_id = ""
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      status: status,
      production_id: production_id,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`production-stock/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const getStockCountById = function (id) {
  return new Promise((res, rej) => {
    ApiService.getWithoutSlashNoCancel(`production-stock/${id}/count`).then(function (response) {
      res(response.data);
    });
  });
};
const jsonToInterfaceProductionStock = jsonToInterface;
const getEmptyObjOfProductionStock = productionStockObjClone;
export {
  ProductionStock,
  getProductionStocks,
  deleteProductionStock,
  addProductionStock,
  getProductionStockById,
  jsonToInterfaceProductionStock,
  productionStockObjClone,
  exportProductionStockData,
  exportPDFProductionStocks,
  getEmptyObjOfProductionStock,
  getStockCountById
};

export default ProductionStocks;
